import React, { PureComponent } from "react"
import { ContentWrapper } from "./Content.styles"
import Container from "@material-ui/core/Container"

import { Controller, Scene } from "react-scrollmagic"

import MyHeader from "../MyHeader/MyHeader"

class Content extends PureComponent {
    render() {
        return (
            <div>
                <div id="content" />
                <ContentWrapper>
                    <Container maxWidth="md">
                        <Controller>
                            <Scene
                                classToggle={[".content.header", "inView"]}
                                triggerElement="#content"
                                indicators={false}
                                reverse={false}
                                triggerHook="0.6"
                            >
                                <MyHeader name="content" animated>
                                    OCELOVÁ VLÁKNA
                        </MyHeader>
                            </Scene>
                        </Controller>

                        <p>Díky eliminaci tradičního vyztužení ocelovými drátěnými síty se snižují investiční nároky, což je způsobeno díky úsporám materiálu a práce. Výzkum využití ocelových vláken, jako přísad do betonu ukázal, že správné používání ocelových vláken vede ke snížení přímých investičních nákladů a nákladů v tzv. zohlednění trvanlivosti konstrukce a omezení údržby a oprav.</p>

                        <p>Velkou výhodou je možnost využití všech běžných metod dodávání betonových směsí na staveniště. Ocelová vlákna mohou být míchána s betonovou hmotou jak v betonárnách, tak v betonových směsích na staveništi, což umožňuje pružnější plánování prací a má samozřejmě pozitivní vliv na investiční náklady. Beton s ocelovými vlákny steelbet® může být nalit do tenčích vrstev než tradičně vyztužený beton, což z něj činí vynikající materiál pro opravu poškozených podlah a jiných povrchů.</p>

                        <h3>Typy ocelových ocelových vláken a základní technické parametry</h3>

                        <figure>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Označení<br />
                    \<br />
                      Parametr</th>
                                        <th>Steelbet<br />50/0,65</th>
                                        <th>Steelbet<br />50/0,75</th>
                                        <th>Steelbet<br />50/1,0</th>
                                        <th>Steelbet<br />30/0,5</th>
                                    </tr>
                                    <tr>
                                        <th>d<br />[mm]</th>
                                        <td>0,65</td>
                                        <td>0,75</td>
                                        <td>1,0</td>
                                        <td>0,5</td>
                                    </tr>
                                    <tr>
                                        <th>L<br />[mm]</th>
                                        <td>50</td>
                                        <td>50</td>
                                        <td>50</td>
                                        <td>30</td>
                                    </tr>
                                    <tr>
                                        <th>l<br />[mm]</th>
                                        <td>4</td>
                                        <td>4</td>
                                        <td>4</td>
                                        <td>3</td>
                                    </tr>
                                    <tr>
                                        <th>h<br />[mm]</th>
                                        <td>&ge;3</td>
                                        <td>&ge;3</td>
                                        <td>&ge;3</td>
                                        <td>&ge;2</td>
                                    </tr>
                                    <tr>
                                        <th>Štíhlost<br />&lambda;</th>
                                        <td>77</td>
                                        <td>67</td>
                                        <td>50</td>
                                        <td>60</td>
                                    </tr>
                                    <tr>
                                        <th>Materiál</th>
                                        <td colSpan="4">ocelový drát kruhového průřezu z nízkouhlíkaté oceli dle EN10016-2</td>
                                    </tr>
                                    <tr>
                                        <th>Pevnost v tahu</th>
                                        <td colSpan="4">Rm = 1100MPa</td>
                                    </tr>
                                    <tr>
                                        <th>Referenční dokument</th>
                                        <td>EN14889-1</td>
                                        <td>EN14889-1</td>
                                        <td>EN14889-1</td>
                                        <td>EN14889-1</td>
                                    </tr>
                                </tbody>
                            </table>
                            <figcaption>Tabulka uvádí jmenovité hodnoty parametrů. Přípustné odchylky a tolerance jsou uvedeny v příslušných referenčních dokumentech a technických listech výrobků.</figcaption>
                        </figure>

                        <h3>Kvalita</h3>

                        <p>Výrobek má příslušná technická schválení stavebního ústavu ve Varšavě a hygienické osvědčení Národního hygienického ústavu. Způsobilost oceli steelbet® byla potvrzena výsledky testů kompozitních betonů z Technické univerzity v Poznani a Vysoké školy technické v čenstochové. Trvale vysoká kvalita vláken steelbet® je zaručena efektivně fungujícím systémem kvality výroby</p>

                        <h3>Projekty</h3>

                        <p>Nabízíme poradenství v oblasti využití rozptýlené výztuže. Vlastní projekční kancelář pomáhá při nezbytných výpočtech a projekčních pracích spojených s použitím ocelových vláken v betonových konstrukcích. Přímý kontakt s projektantem zaručuje optimální výběr ocelových vláken v závislosti na požadavcích kladených na betonové podlahy.</p>

                        <h3>Výhody</h3>
                        <ul>
                            <li>zvýšení pevnostních parametrů betonu</li>
                            <li>odstranění škrábanců a trhlin na povrchu podlahy</li>
                            <li>snížení výrobních nákladů zvýšením efektivity práce a úspor na materiálu</li>
                            <li>vysoká kvalita provedení povrchu</li>
                            <li>zkrácení doby trvání investiční akce</li>
                            <li>prodloužení životnosti betonových konstrukcí</li>
                            <li>nižší náklady na údržbu povrchu</li>
                        </ul>

                        <h3>Vlastnosti</h3>

                        <ul>
                            <li>zvýšená odolnost proti nárazům a dynamickým zatížením</li>
                            <li>vysoká odolnost vůči statickým zatížením</li>
                            <li>vyšší odolnost proti otěru, odlupování a prasklinám</li>
                            <li>vysoká odolnost vůči náhlým změnám teploty</li>
                            <li>beton s ocelovým vláknem steelbet® je homogenní materiál, díky homogenní struktuře materiálu je snazší získat dokonale hladké povrchy</li>
                        </ul>

                        <h3>Aplikace</h3>

                        <ul>
                            <li>průmyslové stavby
                <ul>
                                    <li>průmyslové podlahy a dlažby silně zatížené</li>
                                    <li>základy pro stroje</li>
                                    <li>základové piloty</li>
                                </ul>
                            </li>
                            <li>stavba komunikací
                <ul>
                                    <li>povrchy letišť a silnic</li>
                                    <li>mosty</li>
                                    <li>tunely</li>
                                    <li>parkovací plochy</li>
                                </ul>
                            </li>
                            <li>hydrotechnické stavby
                <ul>
                                    <li>konstrukční prvky vystavené dynamickým zatížením</li>
                                    <li>části přístavních mol</li>
                                    <li>speciální stavby (klenby, trezory, dělící stěny, vojenské stavby, jímky na škodlivé odpady)</li>
                                    <li>obecné konstrukce, betonové prefabrikáty, segmenty tunelů, kruhy studní, opravy a renovace budov a staveb (silnice, mosty, vodní stavby)</li>
                                </ul>
                            </li>
                        </ul>

                        <h3>Balení</h3>

                        <p>Ocelová vlákna jsou balena v lepenkových krabicích o váze:</p>

                        <ul>
                            <li>20 kg - steelbet typ 50 / 0,8</li>
                            <li>25 kg - všechny ostatní typy vláken</li>
                        </ul>

                        <p>Obal obsahuje potřebné označení obsahující název výrobku spolu s údajem o dokumentu umožňujícím uvedení vlákna z oceli na trh v souladu se zákonem o stavebních výrobcích. Vlákna se dodávají v hromadných obalech o hmotnosti 1000 kg nebo 1250 kg, chráněných proti atmosférickému srážení pomocí teplem smrštitelné fólie. Pevnost obalu zaručuje možnost delšího skladování na betonových míchacích uzlech bez rizika koroze vláken.</p>

                    </Container>
                </ContentWrapper>
            </div>
        )
    }
}

export default Content
