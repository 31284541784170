import React, { PureComponent } from "react"

import { FooterDiv } from "./Footer.styles"

class Footer extends PureComponent {
  render() {
    return <FooterDiv>Copyright Steelbet 2023</FooterDiv>
  }
}

export default Footer
