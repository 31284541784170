import React, { PureComponent } from "react"
import Container from "@material-ui/core/Container"
import { ContactWrapper } from "./Contact.styles"
import "./Contact.scss"

import { Controller, Scene } from "react-scrollmagic"

import CardContent from "@material-ui/core/CardContent"
import Card from "@material-ui/core/Card"
import Grid from "@material-ui/core/Grid"

import MyHeader from "../MyHeader/MyHeader"

import PhoneIcon from "@material-ui/icons/Phone"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import HomeIcon from "@material-ui/icons/Home"
import { AnimatedDiv } from "../App.styles"

class Contact extends PureComponent {
  render() {
    return (
      <div>
        <div id="kontakt" />
        <ContactWrapper>
          <Container maxWidth="md">
            <Controller>
              <Scene
                classToggle={[".contact.header", "inView"]}
                triggerElement="#kontakt"
                indicators={false}
                reverse={false}
                triggerHook="0.6"
              >
                <MyHeader name="contact" animated>
                  KONTAKT
                </MyHeader>
              </Scene>
              <Scene
                classToggle={[".contactCard", "inView"]}
                triggerElement="#contactCardTrigger"
                indicators={false}
                reverse={false}
                triggerHook="0.6"
              >
                <AnimatedDiv className="contactCard" animated>
                  <div id="contactCardTrigger" />
                  <Card
                    className="card"
                    square={true}
                    boxshadow={3}
                    animated="true"
                  >
                    <CardContent>
                      <Grid
                        justify="center"
                        className="contactContent"
                        container
                        spacing={3}
                      >
                        <Grid item xs={12} sm={6}>
                          <section>
                            <HomeIcon />
                            <br />
                            <b>Vladimír Blahož</b>
                            <br />
                            Landrax, s.r.o.
                            <br /> Místecká 329/258
                            <br />
                            720 00
                            <br />
                            Ostrava-Hrabová
                          </section>
                          <section>
                            <b>IČO</b> 25386280
                          </section>
                          <br />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <section>
                            <PhoneIcon />
                            <br />
                            +420 605 268 096
                            <br />
                            +420 731 653 691
                            <br />
                            +420 596 711 001
                            <br />
                          </section>
                          <section>
                            <MailOutlineIcon />
                            <br />
                            Email
                            <br />
                            <a href="mailto:blahoz@landrax.cz">
                              blahoz@landrax.cz
                            </a>
                          </section>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </AnimatedDiv>
              </Scene>
            </Controller>
          </Container>
        </ContactWrapper>
      </div >
    )
  }
}

export default Contact
