import styled from "styled-components"
import phone from "../resource/image/background/phone.jpg"

export const ContactWrapper = styled.div`
  background-color: ${props => props.theme.bg};
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(${phone});
  background-size: cover;
  background-position: top center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: ${props => props.theme.color};
  padding: 10%;

  & .contact {
    padding-bottom: 10%;
  }

  & .card {
    background-color: ${props => props.theme.bgTransparent};
    color: ${props => props.theme.color};
  }

  & section {
    padding-top: 10px;
  }

  & svg {
    color: ${props => props.theme.primary};
  }

  & a {
    color: ${props => props.theme.primary};
  }
`
