import styled from "styled-components"

export const MenuWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 25%;
  top: auto;
  left: 0%;
  right: 0%;
  justify-content: center;
  align-items: center;
`
export const MenuBlock = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  flex-wrap: wrap;
`
