import React from "react"
import Home from "./Home/Home"
import About from "./About/About"
import Contact from "./Contact/Contact"
import SiteBar from "./SiteBar/SiteBar"
import Content from "./Content/Content"
import "./App.scss"
import { ThemeProvider } from "styled-components"
import { theme, lightTheme } from "./App.styles"
import Documents from "./Documents/Documents"
import Footer from "./Footer/Footer"

const App: React.FC = () => {
  return (
    <div className="App">
      {/*<ThemeProvider theme={lightTheme}>
        <SiteBar />
  </ThemeProvider>*/}
      <ThemeProvider theme={theme}>
        <Home />
      </ThemeProvider>
      <ThemeProvider theme={theme}>
        <About />
      </ThemeProvider>
      <ThemeProvider theme={theme}>
        <Content />
      </ThemeProvider>
      <ThemeProvider theme={lightTheme}>
        <Documents />
      </ThemeProvider>
      <ThemeProvider theme={theme}>
        <Contact />
      </ThemeProvider>
      <ThemeProvider theme={lightTheme}>
        <Footer />
      </ThemeProvider>
    </div>
  )
}

export default App
