import React, { PureComponent } from "react"
import { MenuWrapper, MenuBlock } from "./Menu.styles"
import MenuItem from "./MenuItem/MenuItem"

class Menu extends PureComponent {
  render() {
    return (
      <MenuWrapper>
        <MenuBlock>
          <MenuItem anchor="about">STEELBET</MenuItem>
          <MenuItem anchor="content">OCELOVÁ VLÁKNA</MenuItem>
          <MenuItem anchor="dokumenty">DOKUMENTY</MenuItem>
          <MenuItem anchor="kontakt">KONTAKT</MenuItem>
        </MenuBlock>
      </MenuWrapper>
    )
  }
}

export default Menu
