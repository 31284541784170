import styled from "styled-components"

export const animated = `
    overflow: hidden;
    position: relative;
    left: -100vw;
    transition: left 1s;

    &.inView {
      left: 0vw;
    }
  `

export const theme = {
  primary: "#19f6e8",
  bg: "#282c34",
  bgTransparent: "rgba(40, 44, 52, 0.7);",
  color: "white"
}

export const lightTheme = {
  primary: "rgb(8, 63, 79)",
  bg: "rgb(110,150,150)",
  bgTransparent: "rgb(110,150,150, 0.7)",
  color: "black"
}

export const AnimatedDiv = styled.div`
  ${props => props.animated && `${animated}`}
`
