import styled from "styled-components"

export const DocumentsWrapper = styled.div`
  padding: 10%;
  background-color: ${props => props.theme.bg};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: ${props => props.theme.color};

  & .documents {
    padding-bottom: 10%;
  }

  div.document {
    margin-top: 10px;
    padding-top: 20px;
    color: rgb(8, 63, 79);
  }

  .document a {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(8, 63, 79);
    text-decoration-line: none;
    text-decoration-style: initial;
    text-decoration-color: initial;
    text-decoration: none;
    font-size: calc(10px + 2vmin);

    &:hover {
      cursor: pointer;
    }

    & svg {
      font-size: 20vmin;
    }

    &:hover svg {
      opacity: 0.5;
    }

    &:hover div {
      opacity: 0.5;
    }
  }

`
