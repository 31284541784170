import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { MenuAnchor } from "./MenuItem.styles"

interface IMenuItemProps {
  anchor?: string
  className?: string
}

class MenuItem extends PureComponent<IMenuItemProps> {
  static propTypes = {
    anchor: PropTypes.string,
    className: PropTypes.string
  }

  render() {
    return (
      <MenuAnchor className={this.props.className} href={"#" + this.props.anchor}>
        {this.props.children}
      </MenuAnchor>
    )
  }
}

export default MenuItem
