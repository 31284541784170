import React, { PureComponent } from "react"

import { Background, Gradient } from "./Home.styles"
import Menu from "../Menu/Menu"
import Logo from "./Logo/Logo"
import MenuItem from "../Menu/MenuItem/MenuItem"

import { Controller, Scene } from "react-scrollmagic"
import { Tween, Timeline } from "react-gsap"

class Home extends PureComponent {
  constructor(props) {
    super(props)
    this.tween = null
  }

  componentDidMount() {
    // use the node ref to create the animation
    //this.tween = TweenLite.to(this.myElement, 1, { background-position-y: 100px })
  }

  render() {
    const mottoStyle = {
      textAlign: 'center',
      fontWeight: 'bold'
    };
    return (
      <div className="scrollable-container" >
        <MenuItem className="fixed" anchor="kontakt">OBJEDNAT</MenuItem>
        <Controller>
          <Scene
            triggerElement="#home"
            indicators={false}
            reverse={false}
            triggerHook="0"
            duration="100%"
            reverse={true}
          >
            <Timeline
              wrapper={
                <Background
                  id="home"
                  className="full-screen centering noselect"
                />
              }
            >
              <Tween
                position="0"
                from={{
                  y: 0
                }}
                to={{
                  y: 0
                }}
              >
                <Gradient className="centering">
                  <Logo />
                  <p style={mottoStyle}>
                    Nabízíme využití moderních výztuží betonových povrchů ocelovými vlákny
                  </p>
                  <Menu />
                </Gradient>
              </Tween>
            </Timeline>
          </Scene>
        </Controller>
      </div>
    )
  }
}

export default Home
