import styled from "styled-components"
import "../../App.scss"

export const MenuAnchor = styled.a`
  display: inline-block;
  position: relative;
  margin-right: 30px;
  text-decoration: none;
  color: inherit;
  padding-top: 2%;

  &:last-child {
    margin-right: 0px;
  }

  &:before {
    content: "";
    position: absolute;
    left: 25%;
    bottom: -5px;
    height: 1px;
    width: 50%;
    border-bottom: 3px solid ${props => props.theme.primary};
    transition: width 1s, border-bottom 1s, left 1s;
  }

  &:hover:before {
    width: 100%;
    left: 0%;
    border-bottom: 1px solid ${props => props.theme.primary};
  }

  &.fixed {
    position:fixed;
    top: 10px;
    right: 10px;
    z-index: 999;
    color: white;
    background-color: rgba(0,0,0,0.3);
    font-size: 2rem;
    padding-top: 0%;
  }


`
