import styled from "styled-components"
import { animated } from "../App.styles"

export const HeaderWrapper = styled.div`
  ${props =>
    props.animated &&
    `
      ${animated}
    `}

  position: relative;
  width: 100%;

  & div.overlay {
    font-size: calc(1px + 8vmin);
    text-align: left;
    width: 100%;
    margin-bottom: calc((-1px - 12vmin) / 2);
    opacity: 0.2;
    font-weight: 800;
    position: relative;
  }

  & div.text {
    text-align: left;
    width: 100%;
    font-size: calc(15px + 3vmin);
    color: ${props => props.theme.primary};
    position: relative;
  }
`
