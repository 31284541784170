import React, { PureComponent } from "react"
import { DocumentsWrapper } from "./Documents.styles"
import { Container } from "@material-ui/core"
import { Controller, Scene } from "react-scrollmagic"
import MyHeader from "../MyHeader/MyHeader"
import PictureAsPdf from "@material-ui/icons/PictureAsPdf"

import Grid from '@material-ui/core/Grid';

class Documents extends PureComponent {
  render() {
    return (
      <div>
        <div id="dokumenty" />
        <DocumentsWrapper>
          <Container maxWidth="md">
            <Controller>
              <Scene
                classToggle={[".documents.header", "inView"]}
                triggerElement="#dokumenty"
                indicators={false}
                reverse={false}
                triggerHook="0.6"
              >
                <MyHeader name="documents" animated>
                  DOKUMENTY
                </MyHeader>
              </Scene>
            </Controller>
            <Grid container direction="row" justify="center" alignItems="center" spacing={8}>
              <Grid item >
                <div className="document">
                  <a
                    target="_blank"
                    href={process.env.PUBLIC_URL + "/docs/" + "Prohlaseni_o_vlastnostech.pdf"}
                    className="slide"
                    rel="noopener noreferrer">
                    <PictureAsPdf />
                    <div>
                      Prohlášení o vlastnostech
                    </div>
                  </a>
                </div>
              </Grid>
              <Grid item >
                <div className="document">
                  <a
                    target="_blank"
                    href={process.env.PUBLIC_URL + "/docs/" + "Technicka_karta_CZ.pdf"}
                    className="slide"
                    rel="noopener noreferrer">
                    <PictureAsPdf />
                    <div>
                      Technická karta
                    </div>
                  </a>
                </div>
              </Grid>
            </Grid>
          </Container>
        </DocumentsWrapper>
      </div>
    )
  }
}

export default Documents
