import styled from "styled-components"
import wire1 from "../resource/image/background/wire3.jpg"

// import wire2 from "../resource/image/background/wire2.jpg"

export const Background = styled.header`
  position: relative;
  &:before {
    content: "";
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url(${wire1});
    background-size: cover;
    background-position-x: 50%;
    filter: brightness(70%);
  }
`

export const Gradient = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.4);
`
