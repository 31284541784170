import React, { PureComponent } from "react"
import { HeaderWrapper } from "./MyHeader.styles"

class MyHeader extends PureComponent {
  render() {
    return (
      <HeaderWrapper
        className={`${this.props.name} header noselect`}
        animated={this.props.animated}
      >
        <div className="overlay">{this.props.children}</div>
        <div className="text">{this.props.children}</div>
      </HeaderWrapper>
    )
  }
}

export default MyHeader
