import styled from "styled-components"

export const ContentWrapper = styled.div`
  background-color: ${props => props.theme.bg};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(15px);
  color: ${props => props.theme.color};
  padding: 10%;

  & table {
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    color: black;
    border: 1px solid black;
    border-collapse: collapse; 
    border-spacing:0 0px; 
  }

  & td, & th {
    border: 1px solid black;
    padding: 7px;
  }

  & td {
    font-weight: bold;
  }

  & th {
    font-weight: normal;
  }

  @media (max-width: 767px) {

    font-size: calc(12px);
    padding: 2%;

    & table {
      font-size: 8px;
    }
  }

`
