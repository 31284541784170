import React, { PureComponent } from "react"
import { AboutWrapper } from "./About.styles"
import Container from "@material-ui/core/Container"

import { Controller, Scene } from "react-scrollmagic"

import MyHeader from "../MyHeader/MyHeader"

import technickeParametry from '../resource/image/technicke_parametry_2019.png';

class About extends PureComponent {
  render() {
    return (
      <div>
        <div id="about" />
        <AboutWrapper>
          <Container maxWidth="md">
            <Controller>
              <Scene
                classToggle={[".about.header", "inView"]}
                triggerElement="#about"
                indicators={false}
                reverse={false}
                triggerHook="0.6"
              >
                <MyHeader name="about" animated>
                  STEELBET
                </MyHeader>
              </Scene>
            </Controller>
            <p>
              O důležitosti dobrého a trvanlivého betonového povrchu nemusíte nikoho přesvědčovat. Beton je křehký materiál, jehož struktura se při nadměrném namáhání nebo deformaci rozpadá. Je samozřejmé, že se těmto situacím snažíme vyhnout. Opravy poškozených povrchů jsou samy o sobě nákladné a navíc je třeba pamatovat na obtíže v činnosti firem, které jsou spojeny s jakoukoli renovací. To je důvod, proč je tak důležitá každá akce ke zlepšení kvality povrchu a tím i prodloužení jeho životnosti.
            </p>
          </Container>
        </AboutWrapper>
      </div>
    )
  }
}

export default About
