import React, { PureComponent } from "react"

import { LogoName, SubName, Rect, Svg } from "./Logo.styles"

class Logo extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      hasError: false
    }
  }

  render() {
    return (
      <div>
        <Svg xmlns="http://www.w3.org/2000/svg">
          <Rect />
        </Svg>
        <LogoName>STEELBET</LogoName>
        <SubName>
          <h2>Ocelová vlákna do betonu</h2>
        </SubName>
      </div>
    )
  }
}

export default Logo
