import { mediaBreakpointDownSm } from "styled-bootstrap-responsive-breakpoints"
import styled from "styled-components"

export const LogoName = styled.h1`
  font-size: 70px;
  font-weight: 400;
  color: ${props => props.theme.color};
  padding-left: 28px;
  padding-right: 28px;
  margin-top: -100px;
  -webkit-animation-name: makeVisible; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1.5s; /* Safari 4.0 - 8.0 */
  animation-name: makeVisible;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  margin-block-end: 0px;

  ${mediaBreakpointDownSm`
    font-size: 40px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: -58px;
  `}

  @-webkit-keyframes makeVisible {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  /* Standard syntax */
  @keyframes makeVisible {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export const SubName = styled.div`
  text-align: right;
  overflow: hidden;

  & h2 {
    position: relative;
    top: -30px;

    font-size: calc(10px + 2vmin);
    margin-block-start: 0px;

    -webkit-animation-name: slideFromTop; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 1s; /* Safari 4.0 - 8.0 */
    animation-name: slideFromTop;
    animation-duration: 0.6s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
  }

  @-webkit-keyframes slideFromTop {
    0% {
      top: -30px;
    }
    100% {
      top: 0px;
    }
  }

  /* Standard syntax */
  @keyframes slideFromTop {
    0% {
      top: -30px;
    }
    100% {
      top: 0px;
    }
  }
`

export const Svg = styled.svg`
  width: 400px;
  height: 100px;

  ${mediaBreakpointDownSm`
    width: 228px;
    height: 57px;
  `}
`

export const Rect = styled.rect`
  stroke-dasharray: 0 1000;
  stroke-dashoffset: 0;
  width: 400px;
  height: 100px;
  stroke-width: 11px;
  fill: transparent;
  stroke: ${props => props.theme.color};
  -webkit-animation-name: rectDraw; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1.5s; /* Safari 4.0 - 8.0 */
  animation-name: rectDraw;
  animation-duration: 1.5s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;

  ${mediaBreakpointDownSm`
    width: 228px;
    height: 57px;
    stroke-width: 6.2px;
  `}

  @-webkit-keyframes rectDraw {
    0% {
      stroke-dasharray: 0 1000;
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dasharray: 1000 1000;
      stroke-dashoffset: 0;
    }
  }

  /* Standard syntax */
  @keyframes rectDraw {
    0% {
      stroke-dasharray: 0 1000;
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dasharray: 1000 1000;
      stroke-dashoffset: 0;
    }
  }
`
